import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './AnchorLink.module.scss';

const AnchorLink = ({ children, title, id, className = '' }) => {
    if (!id) {
        return <span>{title || children}</span>;
    }

    const classes = classNames(s['Root'], 'Anchor', {
        [`${className}`]: className,
    });
    return (
        <a className={classes} href={`#${id}`} id={id}>
            <span>{title || children}</span>
        </a>
    );
};

AnchorLink.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
};

export default AnchorLink;
